<template>
  <div>
       <b-table hover responsive
      :items="itemsProp"
      :current-page="currentPage"
      :fields="fields"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      small
    >
         <template #cell(summary)="row">
        <button v-if="!row.item.isManager || row.item.isManager == 'no'" class="btn purple-btn mt-1" @click="toggleManager(row, true)">Make Manager</button>
        <button class="btn btn-danger mt-1" v-else @click="toggleManager(row, false)">Remove Manager</button>
        <button class="btn btn-success mt-1" @click="toggleActivation(row.item)" v-if="row.item.userRoles.length == 0">Reactivate</button>
        <button class="btn btn-danger mt-1" @click="toggleActivation(row.item)" v-else>Deactivate</button>
        <button class="btn purple-btn mt-1 ml-1" @click="displayEditModal(row.item)">Edit</button>
        <button class="btn btn-danger mt-1 ml-1" @click="displayDeleteModal(row.item)">Delete</button>
        <button class="btn purple-btn mt-1 ml-1" @click="displayChangePasswordModal(row.item)">Change Password</button>
        <button class="btn purple-btn mt-1 ml-1" @click="viewAsUser(row.item)" v-if="row.item.userRoles.length != 0">View As User</button>
      </template>
    </b-table>
    <div class="row">
        <div class="col-md-12">
            <a :style="currentPage == number ? 'cursor:pointer; font-size:20px; color:black !important;' : 'cursor:pointer;font-size:20px;'"
             v-for="number in Math.ceil(itemsProp.length / perPage)" :key="number" class="text-primary" @click="setPage(number)">{{number}}&nbsp;&nbsp;</a>
        </div>
    </div>
    <Modal v-model="showDeleteModal" title="Delete User">
                <p>Are you sure you would like to delete this User?</p>
                <button @click="showDeleteModal = false" class="btn purple-btn"> &nbsp;&nbsp; Back</button>
                <button @click="del()" style="float:right;" class="btn btn-danger">Delete </button>
    </Modal>

    <Modal v-model="showEditModal" title="Edit User">
                <div class="row">
                    <div class="col-md-4">
                        <label for="edit-user-email" class="mt-1">
                            Email:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-email" v-model="editUser.contactEmail">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-firstname" class="mt-1">
                            First Name:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-firstname" v-model="editUser.firstName">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-lastname" class="mt-1">
                            Last Name:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-lastname" v-model="editUser.lastName">
                    </div>
                </div>
                 <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-primaryphone" class="mt-1">
                            Primary Phone:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-primaryphone" v-model="editUser.contactPhone1">
                    </div>
                </div>
                 <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-secondaryphone" class="mt-1">
                            Secondary Phone:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-secondaryphone" v-model="editUser.contactPhone2">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-address" class="mt-1">
                            Address:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-address" v-model="editUser.address">
                    </div>
                </div>
                 <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-city" class="mt-1">
                            City:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-city" v-model="editUser.city">
                    </div>
                </div>
                 <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-state" class="mt-1">
                            State:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-state" v-model="editUser.state">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-4">
                        <label for="edit-user-certifications" class="mt-1">
                            Certifications:
                        </label>
                    </div>
                    <div class="col-md-8">
                         <input class="form-control" id="edit-user-certifications" v-model="editUser.certifications">
                    </div>
                </div>
                <button @click="showEditModal = false" class="btn purple-btn mt-4"> &nbsp;&nbsp; Back</button>
                <button @click="saveChanges()" style="float:right;" class="btn btn-success mt-4">Save </button>
        </Modal>

    <Modal v-model="showChangePasswordModal" title="Change User Password">
      <div class="row">
        <div class="col-md-4">
          <label for="new-user-password" class="mt-1">
            Password:
          </label>
        </div>
        <div class="col-md-8">
          <input class="form-control" id="edit-user-email" type="password" v-model="editUser.newPassword">
        </div>
      </div>
      <button @click="showChangePasswordModal = false" class="btn purple-btn mt-4"> &nbsp;&nbsp; Back</button>
      <button @click="changePassword()" style="float:right;" class="btn btn-success mt-4">Save </button>
    </Modal>
  </div>
</template>

<script>
import { adminService, managerService } from '../../_services';
import VueModal from '@kouts/vue-modal';

export default {
    components:{
        'Modal':VueModal,
    },
    data(){
        return{
           // items: this.itemsProp,
               showDeleteModal: false,
               showEditModal: false,
               showChangePasswordModal: false,
               editUser: {},
               deleteUser: {},
               totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                fields:[
                {
                    key: 'contactEmail', label: "Contact Email", sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'firstName', label: "First Name", sortable:true, sortDirection: 'desc'
                },
                {
                    key: 'lastName', label: "Last Name", sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'isManager', label: 'Manager?', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'createdOn', label: 'Created On', sortable: true, sortDirection: 'desc'
                },
                {
                    key: 'summary', label: ''
                }
            ],
        }
    },
    props: {
        itemsProp: Array
    },
    methods:{
        setPage: function(number){
            this.currentPage = number;
        },
        saveChanges(){
            adminService.editExistingPartnerUser(this.editUser, this.$route.params.id)
            .then(() => {
                this.$toast.success("Successfully updated user permissions", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            }, () => {
                this.$toast.error("Failed to change user permissions", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            })
        },
        changePassword() {
          adminService.changeUserPassword(this.editUser)
            .then(() => {
              this.$toast.success("Successfully updated user password", {
                position: 'bottom-center',
                timeout: 5000,
                hideProgressBar: true
              })
            }, () => {
              this.$toast.error("Failed to change user password", {
                position: 'bottom-center',
                timeout: 5000,
                hideProgressBar: true
              })
            })
        },
        displayEditModal(item){
            this.showEditModal = true;
            this.editUser = item;
        },
        displayDeleteModal(item){
            this.showDeleteModal = true;
            this.deleteUser = item;
        },

      displayChangePasswordModal(item) {
          this.showChangePasswordModal = true;
          this.editUser = item;
      },

      viewAsUser(item) {
          adminService.viewAsUser(item)
            .then((result) => {
              localStorage.setItem('ViewAsUserAccessToken', result.data.token);
              const location = window.location;
              window.open(location.protocol + '//' + location.host, '_blank');
            },
              () => {
              this.$toast.error("Failed to view as user", {
                position: 'bottom-center',
                timeout: 5000,
                hideProgressBar: true
              })
            });

      },

        toggleActivation(item){
            var shouldActivate = item.userRoles.length == 0;
            adminService.toggleActivation(item.userId, shouldActivate)
            .then(() => {
                if(shouldActivate)
                    item.userRoles = [" new role "]
                else
                    item.userRoles = []
                this.$toast.success("Successfully Modified user", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            }, () => {
                this.$toast.error("Failed to modify user", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            })
        },
        del(){
            adminService.deletePartnerUser(this.deleteUser, this.$route.params.id)
            .then(() => {
                this.$toast.success("Successfully deleted user", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            }, () => {
              this.$toast.error("Failed to delete user", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            })
        },
        toggleManager: function(row, shouldMakeManager){
            managerService.toggleManager(row.item, shouldMakeManager)
            .then(() => {
                if(shouldMakeManager)
                    row.item.isManager = "yes";
                else
                    row.item.isManager = "no"
                 this.$toast.success("Successfully updated user permissions", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            }, () => {
                  this.$toast.error("Failed to change user permissions", {
                    position: 'bottom-center',
                    timeout: 5000,
                    hideProgressBar: true
                })
            })

        }
    }
}
</script>

<style>

</style>
